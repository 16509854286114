import axios from "axios";
import * as settings from '../constants'
import authHeader from './auth-header';
const API_URL = settings.API_BASE_URL + "api/";

class CardService {
  activate(organizationId, licenseId, name, serialNo) {
    return axios.post(API_URL + "organizations/" + organizationId + "/licenses/" + licenseId + "/cards/activate", {
      name,
      serialNo
    }, { headers: authHeader() });
  }
  updateName(organizationId, licenseId, id, name) {
    return axios.post(API_URL + "organizations/" + organizationId + "/licenses/" + licenseId + "/cards/" + id + "/update-name", {
      name
    }, { headers: authHeader() });
  }
}

export default new CardService();