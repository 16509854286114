import { CircularProgress } from "@material-ui/core";
import React, { Component } from "react";

export default class LoadingComponent extends Component {
  render() {
    return (
      <div className="col-md-12 flex-center">
        <CircularProgress />
      </div>
    );
  }
}