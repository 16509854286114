import React, { Component } from "react";
import OrganizationService from "../../services/organization.service"
import Moment from 'moment';
import { Link } from "react-router-dom";
import EditIcon from "../icons/edit-icon";
import LicenseStats from "./license-stats";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ImageIcon from '@material-ui/icons/Image';
import LanguageIcon from '@material-ui/icons/Language';
import LinkIcon from '@material-ui/icons/Link';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ChangeObjectImage from "./change-object-image";
import { CreditCard } from "@material-ui/icons";
import ChangeObjectLogo from "./change-object-logo";
import Cards from "../cards/cards";
import LicenseLink from "../links/license-link";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { MenuItem, Select, Switch } from "@material-ui/core";
import themeService from "../../services/theme.service";
import licenseService from "../../services/license.service";
import linkTypeService from "../../services/link-type-service";
import LoadingComponent from "../common/loading";
import LicenseLanguages from "./license-languages";
import languageService from "../../services/language.service";
import LicenseLinks from "./license-links";
import LicenseLinkEdit from "./license-link-edit";

export default class LicenseDetails extends Component {

  constructor(props) {
    super(props);
    this.onChangeTab = this.onChangeTab.bind(this);
    this.onChangeImgTab = this.onChangeImgTab.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleThemeChange = this.handleThemeChange.bind(this);
    this.handleReviewButtonsChange = this.handleReviewButtonsChange.bind(this);
    this.handleLanguageToggle = this.handleLanguageToggle.bind(this);
    this.handleDefaultLanguageChange = this.handleDefaultLanguageChange.bind(this);
    this.handleLinkMenuChange = this.handleLinkMenuChange.bind(this);
    this.handleLinkSelect = this.handleLinkSelect.bind(this);
    this.state = {
      loading: true,
      organizationId: this.props.match.params.organizationId,
      licenseId: this.props.match.params.licenseId,
      license: {longReviewButtons: true},
      tabValue: 0,
      imgTabValue: 0,
      themes: [],
      languages: [],
      random: 0,
      message: "",
      linksParentId: "",
      breadcrumbs: [{name: "Links", parentId: null, linkId: null}],
      openedLinkId: null,
      linkTypes: null
    };
  }
  onChangeTab(e, newValue) {
    this.setState({
      tabValue: newValue
    });
  }

  onChangeImgTab(e, newValue) {
    this.setState({
      imgTabValue: newValue
    });
  }

  handleTextChange = (event, number) => {
    var license = this.state.license;
    license.links[number].value = event.target.value;
    this.setState({license});
  };

  handleLanguageToggle = (e, language) => {
    var organizationId = this.state.organizationId
    var license = this.state.license
    if(e.target.checked) {
      license.languages.push(language);
      license.languages.sort((a, b) => (a.name > b.name) ? 1 : -1)
      licenseService.addLanguage(organizationId, license.id, language.id);
    }
    else {
      var index = license.languages.findIndex(function(l) {
        return l.id == language.id
      })
      if (index !== -1) { 
        license.languages.splice(index, 1);
        licenseService.removeLanguage(organizationId, license.id, language.id);
      }
    }
    this.setState({license: license})
  }
  handleDefaultLanguageChange = (languageId) => {
    var organizationId = this.state.organizationId
    var license = this.state.license
    if(license.languageId != languageId) {
      license.languageId = languageId;
      this.setState({license: license})
      licenseService.setDefaultLanguage(organizationId, license.id, languageId);
    }
  }

  handleThemeChange = (event) => {
    if(this.state.license.themeId === event.target.value) {
      return
    }
    licenseService.updateTheme(this.state.organizationId, this.state.licenseId, event.target.value).then(
      response => {
        let license = this.state.license
        license.themeId = event.target.value
        let themeKey = license.themeKey = this.state.themes.filter(function(e) {
          return e.id == event.target.value
        })
        if(themeKey.length == 0) {
          license.themeKey = "default";
        }
        else {
          license.themeKey = themeKey[0].keyName
        }
        this.setState({license: license});
        this.resetIframe();
      }
    );
  };
  handleReviewButtonsChange = (event) => {
    const checked = event.target.checked
    if(this.state.license.longReviewButtons === checked) {
      return
    }
    licenseService.updateLongReviewButtons(this.state.organizationId, this.state.licenseId, checked).then(
      response => {
        let license = this.state.license
        license.longReviewButtons = checked
        this.setState({license: license});
        this.resetIframe();
      }
    );
  };
  handleLinkMenuChange = (parentId) => {
    let breadcrumbs = this.state.breadcrumbs
    var index = breadcrumbs.map(function(e) { return e.parentId; }).indexOf(parentId)
    if(index === -1) {
      breadcrumbs.push({name: parentId === null ? "Links" : "Menu", parentId: parentId, linkId: null})
    }
    else {
      while(breadcrumbs.length - 1 > index) {
        breadcrumbs.pop()
      }
    }
    this.setState({
      openedLinkId: null,
      linksParentId: parentId ?? "",
    })
  }
  handleLinkSelect = (parentId, linkId, name) => {
    let breadcrumbs = this.state.breadcrumbs
    var index = breadcrumbs.map(function(e) { return e.linkId; }).indexOf(linkId)
    if(index === -1) {
      breadcrumbs.push({name: name, parentId: parentId, linkId: linkId})
    }
    else {
      while(breadcrumbs.length - 1 > index) {
        breadcrumbs.pop()
      }
    }
    this.setState({
      breadcrumbs: breadcrumbs,
      openedLinkId: linkId
    });
  }
  resetIframe() {
    this.setState({random: this.state.random + 1})
  }
  componentDidMount() {
    themeService.getThemes().then(
      response => {
        this.setState({ themes: response.data.sort((a, b) => (a.name > b.name) ? 1 : -1) })
      }
    );
    languageService.getLanguages().then(
      response => {
        this.setState({ languages: response.data.sort((a, b) => (a.name > b.name) ? 1 : -1) })
      }
    );
    var linkTypes = linkTypeService.getLinkTypes().then(
      response => {
        var linkTypes = this.state.linkTypes
        this.setState({
          linkTypes: response.data.sort((a, b) => (a.name > b.name) ? 1 : -1)
        })
      }
    );
    OrganizationService.readLicense(this.state.organizationId, this.state.licenseId).then
    (
      response => {
        response.data.links.sort((a, b) => (a.order > b.order) ? 1 : -1)
        response.data.languages.sort((a, b) => (a.name > b.name) ? 1 : -1)
        this.setState({
          license: response.data,
          loading: false
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }
  render() {
    if(this.state.loading === true) {
      return <LoadingComponent />;
    }
    else {
      Moment.locale('en');
      const classes = useStyles;
      const licenseLinks = [];
      const value = this.state.tabValue;
      const imgTabValue = this.state.imgTabValue;
      const links = this.state.license.links;
      const organizationId = this.state.organizationId;
      const licenseId = this.state.licenseId;
      const rThis = this;
      const linkTypes = this.state.linkTypes
      const license = this.state.license
      if(typeof(links) !== 'undefined') {
        links.forEach(function(item, index) {
            licenseLinks.push(<LicenseLink organizationId={organizationId} handleTextChange={rThis.handleTextChange} licenseId={licenseId} linkType={linkTypes.filter(function(e) {
              return e.id === item.linkTypeId
            })[0]} key={index} number={index} themeKey={license.themeKey} {...item} />)
        });
      }
      return (
        <div>
          <div className={classes.root}>
            <AppBar position="relative" color="default">
              <Tabs
                value={this.state.tabValue}
                onChange={this.onChangeTab}
                variant="scrollable"
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="License options"
              >
                <Tab label="Statistics" icon={<AssessmentIcon />} {...a11yProps(0)} />
                <Tab label="Appearance" icon={<ImageIcon />} {...a11yProps(1)} />
                <Tab label="Languages" icon={<LanguageIcon />} {...a11yProps(2)} />
                <Tab label="Links" icon={<LinkIcon />} {...a11yProps(3)} />
                <Tab label="Cards" icon={<CreditCard />} {...a11yProps(4)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
            <LicenseStats organizationId={this.state.organizationId} licenseId={this.state.licenseId}/>
            </TabPanel>
            <TabPanel value={value} index={1} className="img-tab-wrapper">
              <AppBar position="static" color="default">
                <Tabs
                  value={this.state.imgTabValue}
                  onChange={this.onChangeImgTab}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="Appereance"
                  centered
                >
                  <Tab label="Preview" icon={<VisibilityIcon />} {...a11xProps(0)} />
                  <Tab label="Object Image" icon={<ImageIcon />} {...a11xProps(1)} />
                  <Tab label="Logo" icon={<ImageIcon />} {...a11xProps(2)} />
                </Tabs>
              </AppBar>
              <TabPanel value={imgTabValue} index={0} className="align-center">
                  Long review buttons: <Switch
                    checked={this.state.license.longReviewButtons}
                    name="longReviewButtons"
                    onChange={this.handleReviewButtonsChange}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <br />
                Theme: <Select 
                onChange = {this.handleThemeChange}
                value={this.state.license.themeId !== "00000000-0000-0000-0000-000000000000" ? this.state.license.themeId : this.state.themes.find(x => x.keyName == "default").id}>
                  {this.state.themes.map((x, i) => {
                    return <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
                  })}
                  </Select><br />
                  <br />

                <iframe key={this.state.random} title="preview" className="license-view" src={this.state.license.domain + "/" + this.state.license.id + "?preview=true&licenseView=true" } />
              </TabPanel>
              <TabPanel value={imgTabValue} index={1}>
                <ChangeObjectImage organizationId={this.state.organizationId} licenseId={this.state.licenseId} license={this.state.license} />
              </TabPanel>
              <TabPanel value={imgTabValue} index={2}>
                <ChangeObjectLogo organizationId={this.state.organizationId} licenseId={this.state.licenseId} license={this.state.license} />
              </TabPanel>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <h3>Languages</h3>
              <LicenseLanguages handleDefaultLanguageChange={this.handleDefaultLanguageChange} handleLanguageToggle={this.handleLanguageToggle} {...this.state} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              { this.state.openedLinkId === null && (
              <div>
              <LicenseLinks handleLinkSelect={this.handleLinkSelect} handleLinkMenuChange={this.handleLinkMenuChange} {...this.state}/></div>)}
              { this.state.openedLinkId !== null && (
              <div>
              <LicenseLinkEdit handleLinkSelect={this.handleLinkSelect} handleLinkMenuChange={this.handleLinkMenuChange} {...this.state}/></div>)}

            </TabPanel>
            <TabPanel value={value} index={4}>
              <Cards organizationId={this.state.organizationId} licenseId={this.state.licenseId}/>
            </TabPanel>
          </div>
          <div className="hidden">
            <h2>{ this.state.license.name } <Link to={"/organizations/" + this.state.organizationId + "/licenses/" + this.state.licenseId + "/edit"} className="edit"><EditIcon /></Link></h2>
            { Moment(this.state.license.expirationDate).format('L') } <br /><br />
          </div>
        </div>
      );
    }
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  //<DigitalMenuComponent organizationId={this.state.organizationId} licenseId={this.state.licenseId}/>
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

function a11xProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));
