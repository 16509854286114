import React, { Component } from "react";
import { Link } from "react-router-dom";
import LoadingComponent from "../common/loading"
import OrganizationService from "../../services/organization.service"
import AuthService from "../../services/auth.service"
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Moment from 'moment';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { Checkbox } from "@material-ui/core";
import authService from "../../services/auth.service";
import { ROLE_CONSTANTS, SERVER_BASE_URL } from "../../constants";
import agentService from "../../services/agent.service";

export default class Licenses extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this)
    this.state = {
      loading: true,
      organizationId: this.props.organizationId,
      licenses: [],
      message: ""
    };
  }

  onChange(licenseId, e) {
    var inPresentation = e.target.checked;
    if(inPresentation) {
      agentService.addLicenseToPresentation(licenseId).then(
      response => {
        this.updateLicenseInPresentation(licenseId, inPresentation)
      })
    }
    else {
      agentService.removeLicenseFromPresentation(licenseId).then(
      response => {
        this.updateLicenseInPresentation(licenseId, inPresentation)
      })
    }
  }
  updateLicenseInPresentation(licenseId, inPresentation) {
    var licenses = this.state.licenses
    licenses.forEach((license, index) => {
      if(license.id === licenseId) {
        licenses[index].inPresentation = inPresentation;
      }
    });
    this.setState({licenses: licenses})
  }
  componentDidMount() {
    OrganizationService.readLicenses(this.props.organizationId).then
    (
      response => {
        this.setState({
          loading: false,
          licenses: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }
  render() {
    if(this.state.loading === true) {
      return <LoadingComponent />;
    }
    else {
      
      const organizationId = this.state.organizationId;
      const canAdd = AuthService.getCurrentUser().role === "AGENT" || AuthService.getCurrentUser().role === "ADMIN";
      const classes = useStyles;
      return (
        <div className="licenses-wrapper">
          <h3>Licenses</h3>
          {canAdd && (<Link to={"/organizations/" + this.state.organizationId + "/licenses/create"} className="btn btn-primary">Add license</Link>)}
          <div className={classes.root}>
            <br />
            <GridList cellHeight={180} className={classes.gridList + " flex-center"}>
              {this.state.licenses.map((license) => (
                  <GridListTile className="license-image-background" key={license.id}>
                    <Link key={license.id} to={"/organizations/" + organizationId + "/licenses/" + license.id}>
                    <img className="license-image" src={license.image + "-logo"} alt={license.name} onError={(e) => { if(e.target.src != SERVER_BASE_URL + "/static/images/logo.png?" + Date.now()) {e.target.src = SERVER_BASE_URL + "/static/images/logo.png";}}}/>
                    </Link>
                    <GridListTileBar className="license-title"
                      title={license.name}
                      subtitle={<div>{Moment(license.expirationDate).format('L')}<br />
                      <div className={ authService.getCurrentUser().role === ROLE_CONSTANTS.Agent ? '' : 'hidden' }>
                        <Checkbox style={{color: '#FF0000'}} onChange={(e) => this.onChange(license.id, e)} checked={license.inPresentation} /> Presentation
                      </div></div>}
                    />
                  </GridListTile>
              ))}
            </GridList>
          </div>
        </div>
      );
    }
  }
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));