import React, { Component } from "react";

export default class BreadcrumbsComponent extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <h3>{ this.props.breadcrumbs.map((breadcrumb, index) => {
        if(this.props.breadcrumbs.length -1 === index) {
          return <span key={index}> {index === 0 ? "" : ">"} {breadcrumb.name}</span>  
        }
        return <span key={index}> {index === 0 ? "" : ">"} <a href="#" onClick={((e) => {
          if(breadcrumb.linkId !== null) {
            this.props.handleLinkSelect(null, breadcrumb.linkId, breadcrumb.name)
          }
          else {
            this.props.handleLinkMenuChange(breadcrumb.parentId)
          }
        })}>{breadcrumb.name}</a></span>
      }) }</h3>
    );
  }
}