import axios from "axios";
import * as settings from '../constants'
import authHeader from './auth-header';
const API_URL = settings.API_BASE_URL + "api/";

class AgentService {
  addLicenseToPresentation(licenseId) {
    return axios.post(API_URL + "agents/add-license-to-presentation/" + licenseId, {data: {}}, { headers: authHeader() });
  }
  removeLicenseFromPresentation(licenseId) {
    return axios.delete(API_URL + "agents/remove-license-from-presentation/" + licenseId, { headers: authHeader() }, {data: {}});
  }
}

export default new AgentService();