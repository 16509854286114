import React, { Component } from "react";
import { Link } from "react-router-dom";
import LoadingComponent from "../common/loading"
import OrganizationService from "../../services/organization.service"
import AuthService from "../../services/auth.service"
import { makeStyles } from '@material-ui/core/styles';
import Card from "./card";
import cardService from "../../services/card-service";
import { Button, GridList, GridListTile, GridListTileBar } from "@material-ui/core";
import { Editable } from "../../helpers/editable";
import * as QRCode from 'qrcode.react'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

export default class Cards extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      loading: true,
      cards: [],
      message: "",
      qrCode: ""
    };
    this.handleCardNameChange = this.handleCardNameChange.bind(this);
    this.downloadQR = this.downloadQR.bind(this);
  }

  componentDidMount() {
    OrganizationService.readLicenseCards(this.props.organizationId, this.props.licenseId).then
    (
      response => {
        const cards = response.data;
        cards.sort((a,b) => a.serialNo-b.serialNo)
        this.setState({
          loading: false,
          cards: cards
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }
  downloadQR(element, cardId, serialNo, domain) {
    this.setState({qrCode: "https://app." + domain + "/" + cardId})
    setTimeout(function() {
      const canvas = document.getElementById("qr-generator");
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = serialNo + ".png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }, 50)
  };
  handleCardNameChange(text, props) {

    var cards = this.state.cards;
    if(cards[props.index].name === text) {
      return;
    }
    cards[props.index].name = text;
    cardService.updateName(this.props.organizationId, this.props.licenseId, cards[props.index].id, text);
    this.setState({cards: cards});
  }

  render() {
    if(this.state.loading === true) {
      return <LoadingComponent />;
    }
    else {
      const classes = useStyles;
      const cards = [];
      const organizationId = this.props.organizationId;
      const handleCardNameChange = this.handleCardNameChange;
      this.state.cards.forEach(function(item, index) {
        cards.push(<Card organizationId={organizationId} key={item.id} handleCardNameChange={handleCardNameChange} cardId={index} {...item} />)
      });
      const canAdd = AuthService.getCurrentUser().role === "AGENT" || AuthService.getCurrentUser().role === "ADMIN";
      return (
        <div className="align-center">
          <h3>Cards:</h3>
          <div className={classes.root}>
            <br />
            <GridList cellHeight={180} className={classes.gridList + " flex-center"}>
              {this.state.cards.map((card, index) => (
                  <GridListTile className="license-image-background" key={card.id}>
                    <Editable name="value" labelClassName="cut-text cursor-pointer" index={index} cardId={card.id} text={ card.name } onFocusOut={handleCardNameChange} inputWidth="100%" labelPlaceHolder={"Click to enter"} />
                    <Button variant="contained" color="primary" component="span" className="btn-card" onClick={(e) => { this.downloadQR(e, card.id, card.serialNo, card.domain); }}>QR code&nbsp;&nbsp; <CloudDownloadIcon /></Button>
                    <GridListTileBar className="license-title"
                      title={card.serialNo}
                    />
                  </GridListTile>
              ))}
            </GridList>
          </div>
          {canAdd && (<Link to={"/organizations/" + this.props.organizationId + "/licenses/" + this.props.licenseId + "/cards/create"} className="btn btn-primary">Add card</Link>)}
          <div className="hidden">
            <QRCode id="qr-generator" level={"M"} value={ this.state.qrCode === "" ? "n" : this.state.qrCode } size={300} includeMargin={true} />
          </div>
        </div>
      );
    }
  }
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));