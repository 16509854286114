import axios from "axios";
import * as settings from '../constants'
import authHeader from './auth-header';
const API_URL = settings.API_BASE_URL + "api/";

class ThemeService {

  getThemes() {
    return axios.get(API_URL + "themes/", { headers: authHeader() });
  }
}

export default new ThemeService();