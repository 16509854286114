import axios from "axios";
import * as settings from '../constants'
import authHeader from './auth-header';
const API_URL = settings.API_BASE_URL + "api/";

class LinkService {
  update(organizationId, licenseId, id, value, customOption, language = null) {
    return axios.patch(API_URL + "organizations/" + organizationId + "/licenses/" + licenseId + "/links/" + id, {
      value,
      customOption,
      language
    }, { headers: authHeader() });
  }
  
  updateLinkType(organizationId, licenseId, linkId, type) {
    return axios.patch(API_URL + "organizations/" + organizationId + "/licenses/" + licenseId + "/links/" + linkId + "/type/" + type, {}, { headers: authHeader() });
  }

  getTypeLink(organizationId, licenseId, typeKey) {
    return axios.get(API_URL + "organizations/" + organizationId + "/licenses/" + licenseId + "/links/type?typeKey=" + typeKey, { headers: authHeader() });
  }

  addButton(organizationId, licenseId, linkTypeId, parentId) {
    return axios.post(API_URL + "organizations/" + organizationId + "/licenses/" + licenseId + "/links/" + linkTypeId + "/" + (parentId ?? ""), {}, { headers: authHeader() })
  }
  deleteUpload(organizationId, licenseId, linkId, languageCode) {
    return axios.delete(API_URL + "organizations/" + organizationId + "/licenses/" + licenseId + "/link/" + linkId + "/upload/" + languageCode, { headers: authHeader(), data: {} });
  }

}

export default new LinkService();