import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Link } from "react-router-dom";

import { Redirect } from "react-router";
import CardService from "../../services/card-service";

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

export default class AddCard extends Component {
  constructor(props) {
    super(props);

    this.handleActivate = this.handleActivate.bind(this);
    this.onChangeSerialNo = this.onChangeSerialNo.bind(this);
    this.onChangeName = this.onChangeName.bind(this);

    this.state = {
      id: "",
      organizationId: this.props.match.params.organizationId,
      licenseId: this.props.match.params.licenseId,
      serialNo: 0,
      name: ""
    };
  }

  onChangeSerialNo(e) {
    this.setState({
      serialNo: e.target.value
    });
  }

  onChangeName(e) {
    this.setState({
      name: e.target.value
    });
  }

  handleActivate(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
      id: ""
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      CardService.activate(
        this.state.organizationId,
        this.state.licenseId,
        this.state.name,
        this.state.serialNo
      ).then(
        response => {
          this.setState({
            id: response.data.id,
            successful: true
          });
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }
  }

  render() {
    return (
      <div className="col-md-12">
          {this.state.id !== "" && (
              <Redirect to={"/organizations/" + this.state.organizationId + "/licenses/" + this.state.licenseId}></Redirect>
          )}
        <div className="card card-container">
          <img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
          />

          <Form
            onSubmit={this.handleActivate}
            ref={c => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChangeName}
                    validations={[required]}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="serialNo">Serial No</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="serialNo"
                    value={this.state.serialNo}
                    onChange={this.onChangeSerialNo}
                    validations={[required]}
                  />
                </div>

                <div className="form-group">
                  <button className="btn btn-primary btn-block">Connect</button>
                  <Link to={"/organizations/" + this.state.organizationId + "/licenses/" + this.state.licenseId} className="btn btn-danger btn-block">Cancel</Link>
                </div>
              </div>
            )}

            {this.state.message && (
              <div className="form-group">
                <div
                  className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </div>
      </div>
    );
  }
}