import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import AsyncSelect from 'react-select/async';
import ClientService from "../services/client.service";

export default class PanelAgent extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.loadOptions = this.loadOptions.bind(this);

    this.state = {
      inputValue: '',
      selectedValue: null,
      agentsCount: null,
      clientsCount: null,
      subscriptionsCount: null
    };
  }

  handleInputChange(value) {
    this.setState({inputValue: value});
  };

  handleChange(value) {
    this.setState({selectedValue: value});
  }

  loadOptions(value, callback) {
    ClientService.search(value).then(res => callback(res.data));
  };

  componentDidMount() {
    UserService.getAgentPanel().then(
      response => {
        this.setState({
          agentsCount: response.data.agentsCount,
          clientsCount: response.data.clientsCount,
          subscriptionsCount: response.data.subscriptionsCount
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  render() {

    var user = AuthService.getCurrentUser();
    if(user === null) {
      return AuthService.Login();
    }
    if(user.role !== "AGENT" && user.role !== "ADMIN") {
      return false;
    }
    return (
      <div className="container">
        <header className="jumbotron">
        <AsyncSelect
        className="select"
        classNamePrefix="select"
        cacheOptions
        defaultOptions
        placeholder="Find client"
        value={this.state.selectedValue}
        getOptionLabel={e => e.name}
        getOptionValue={e => e.id}
        loadOptions={this.loadOptions}
        onInputChange={this.handleInputChange}
        onChange={this.handleChange}
        />
        <Link to={"/clients/create"} className="btn btn-primary">Add client</Link>
        <RedirectToClient value={this.state.selectedValue}/>
        <br />

        </header>

      </div>
    );
  }
}
function RedirectToClient(value) {
  value = value.value;
  if(value === null || (value !== null && value.id === undefined)) {
    return null;
  }
  var link = "/clients/" + value.id;
  return <Redirect to={link} />;
}