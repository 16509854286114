import axios from "axios";
import * as settings from '../constants'
import authHeader from './auth-header';
const API_URL = settings.API_BASE_URL + "api/clients/";

class ClientService {
  create(organizationName, email, password, phoneNumber) {
    return axios.post(API_URL, {
      organizationName,
      email,
      password,
      phoneNumber
    }, { headers: authHeader() });
  }
  search(key) {
    return axios.get(API_URL + '?limit=10&search=' + key, { headers: authHeader() });
  }
  read(id) {
    return axios.get(API_URL + id, { headers: authHeader() });
  }

  info() {
    return axios.get(API_URL + "info", { headers: authHeader() });
  }
}

export default new ClientService();