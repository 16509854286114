import React, { Component } from "react";
import { SERVER_BASE_URL } from "../../constants";
import { Checkbox, Radio, RadioGroup } from "@material-ui/core";

export default class LicenseLanguages extends Component {
  constructor(props) {
    super(props);
    this.onLanguageToggle = this.onLanguageToggle.bind(this);
    this.onDefaultLanguageChange = this.onDefaultLanguageChange.bind(this);
    this.state = {
    };
  }

  onLanguageToggle(e, value) {
    this.props.handleLanguageToggle(e, value);
  }
  onDefaultLanguageChange(e) {
    this.props.handleDefaultLanguageChange(e.target.value);
  }

  render() {
    const languages = this.props.license.languages
    const onLanguageToggle = this.onLanguageToggle;
    return (
      <div>
        Default / Enabled
        <RadioGroup name="use-radio-group" value={this.props.license.languageId || ""} onChange={this.onDefaultLanguageChange}>
          {this.props.languages.map((language) => { 
            const enabled = languages.filter(function(e) {return e.id === language.id}).length > 0
            return (
          <div key={language.isoCode}>
            <Radio value={language.id} label={language.name} control={<Radio />} disabled={!enabled} />
            <Checkbox value={language.id} onChange={(e) => onLanguageToggle(e, language)} checked={enabled} disabled={language.id === this.props.license.languageId}/>
            <img className="language" src={ SERVER_BASE_URL + "/static/themes/default/flags/" + language.isoCode + ".png"} /> {language.name}</div>
        );
        })}
        </RadioGroup>
      </div>
    );

  }
}