import React, { Component } from "react";
import Moment from 'moment';
import linkService from "../../services/link.service";
import { SERVER_BASE_URL } from "../../constants";

export default class LicenseLink extends Component {
  constructor(props) {
    super(props);
    this.onFocusOut = this.onFocusOut.bind(this)
    this.onChange = this.onChange.bind(this)

    this.state = {
      loading: true,
      licenseId: this.props.licenseId,
      organizationId: this.props.organizationId,
      id: this.props.id,
      linkTypeId: this.props.linkTypeId,
      linkType: this.props.linkType,
      themeKey: this.props.themeKey,
      name: this.props.name,
      value: this.props.value,
      textValue: "",
      order: this.props.order,
      message: "",
    };
  }

  onFocusOut(event) { 
    if(this.props.value === event.target.value) {
      return;
    }

    this.props.handleTextChange(event, this.props.number);
    linkService.update(this.state.organizationId, this.state.licenseId, this.state.id, event.target.value);
  }
  onChange(event) {
    this.setState({textValue: event.target.value})
  }

  componentDidMount() {
    this.setState({textValue: this.props.value})
  }

  render() {
    Moment.locale('en');
      const linkTypeId = this.state.linkTypeId;
      const linkType = this.state.linkType;
      return (
       <div className = "license-link" onClick={((e) => {this.props.handleLinkSelect(this.props.link.parentId, this.props.link.id, this.props.link.name)})}><img width="100" src={SERVER_BASE_URL + "/static/themes/" + this.state.themeKey + "/" + linkType.icon} link-id={this.props.link.id} /><br />{linkType.name}</div>
      );
    }
    // return (
    //   <div className="flex-center">
    //     <TextField
    //       onBlur={this.onFocusOut}
    //       onChange={this.onChange}
    //       className="text-input"
    //       margin="dense"
    //       id={"link-" + this.props.number}
    //       label={this.props.name}
    //       type="text"
    //       value={this.state.textValue}
    //       fullWidth
    //     />
    //   </div>
    // );
}