import React, { Component } from "react";
import LoadingComponent from "../common/loading";
import licenseService from "../../services/license.service";
import LicenseLink from "../links/license-link";
import BreadcrumbsComponent from "../common/breadcrumbs";
import { Button} from "@material-ui/core";
import { ROLE_CONSTANTS, SERVER_BASE_URL } from "../../constants";
import authService from "../../services/auth.service";
import linkService from "../../services/link.service";


export default class LicenseLinks extends Component {
  constructor(props) {
    super(props);
    this.onLinkMenuChange = this.onLinkMenuChange.bind(this)
    this.onAddButtonToggle = this.onAddButtonToggle.bind(this)
    this.addButton = this.addButton.bind(this)
    this.state = {
      loading: true,
      links: [],
      add: false
    };
  }

  componentDidMount() {
    this.getLinks(this.props.linksParentId)
  }
  getLinks(parentId) {
    licenseService.getLinks(this.props.organizationId, this.props.license.id, parentId).then
    (
      response => {
        response.data.sort((a, b) => (a.order > b.order) ? 1 : -1)
        const rThis = this;
        this.setState({
          links: response.data,
          loading: false
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }
  onLinkMenuChange(parentId) {
    if(this.props.openedLinkId === null) {
      this.getLinks(parentId)
    }
    this.props.handleLinkMenuChange(parentId);
  }
  onAddButtonToggle() {
    const add = this.state.add
    this.setState({add: !add})
  }
  addButton(linkTypeId, parentId = null) {
    linkService.addButton(this.props.organizationId, this.props.licenseId, linkTypeId, parentId).then(() => {
      this.getLinks(this.props.linksParentId)
    });
  }
  render() {
    if(this.state.loading === true) {
      return <LoadingComponent />;
    }
    else {
      const user = authService.getCurrentUser()
      const admin = user.role.includes(ROLE_CONSTANTS.Agent) || user.role.includes(ROLE_CONSTANTS.Admin)
      const links = this.state.links
      var theme = this.props.license.themeKey === null || this.props.license.themeKey === "" ? "default" : this.props.license.themeKey
      const rThis = this

      return (
            <div><BreadcrumbsComponent handleLinkSelect={this.props.handleLinkSelect} breadcrumbs={this.props.breadcrumbs} handleLinkMenuChange={this.onLinkMenuChange} /><hr />
            {this.state.links.map((link, index) => {
                return (<LicenseLink key={index} organizationId={rThis.props.organizationId} handleLinkMenuChange={rThis.onLinkMenuChange} handleLinkSelect={rThis.props.handleLinkSelect} licenseId={rThis.props.license.id} linkType={rThis.props.linkTypes.filter(function(e) {
                return e.id === link.linkTypeId
              })[0]} key={index} number={index} themeKey={theme} link={link} />)
            })}
            <br /><br />
            { admin && (<div><Button onClick={this.onAddButtonToggle}variant="contained" color="primary" component="span">Add button</Button>
            <br /><br />
            { this.state.add && (<div>{ this.props.linkTypes.filter((type) => { return !links.some(link => link.linkTypeId == type.id) }).map((linkType, index) => {
              return <div key={index} className="license-link"><img onClick={((e) => this.addButton(linkType.id, this.props.linksParentId))} width="100" src={ SERVER_BASE_URL + "/static/themes/" + theme + "/" + linkType.icon} /><br />{ linkType.name }</div>
            })}</div>)}
            </div>)}
            </div>
      );
    }
  }
}