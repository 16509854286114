import React, { Component } from "react";
import Moment from 'moment';
import { Editable } from "../../helpers/editable";

export default class Card extends Component {
  constructor(props) {
    super(props);
    this.handleFocusOut = this.handleFocusOut.bind(this);
  }
  handleFocusOut(text, props) {
    this.props.handleCardNameChange(text, props.cardId);
  }

  render() {
    Moment.locale('en');
    return (
      <div>
        <Editable name="value" cardId = {this.props.cardId} text={ this.props.name } onFocusOut={this.handleFocusOut} inputWidth="100%" labelPlaceHolder={"Click to enter"} /> <a target="_blank" rel="noreferrer" href={"https://app." + this.props.domain + "/" + this.props.id + "?preview=true"}>Preview</a>
      </div>
    );
  }
}