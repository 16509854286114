const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0, type = 'jpeg') {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea
  canvas.height = safeArea

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2)
  ctx.rotate(getRadianAngle(rotation))
  ctx.translate(-safeArea / 2, -safeArea / 2)

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  )
  const data = ctx.getImageData(0, 0, safeArea, safeArea)

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height
  
  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  )
  // As Base64 string
  return canvas.toDataURL('image/' + type);

  // As a blob
  /* return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file))
    }, 'image/png')
  })*/
}

export async function getRotatedImage(imageSrc, rotation = 0) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const orientationChanged =
    rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270
  if (orientationChanged) {
    canvas.width = image.height
    canvas.height = image.width
  } else {
    canvas.width = image.width
    canvas.height = image.height
  }

  ctx.translate(canvas.width / 2, canvas.height / 2)
  ctx.rotate((rotation * Math.PI) / 180)
  ctx.drawImage(image, -image.width / 2, -image.height / 2)

  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file))
    }, 'image/png')
  })
}

export async function steppedScale(img, width, height, step, type = 'jpeg') {
    const image = await createImage(img)
    var canvas = document.createElement('canvas'),
    ctx = canvas.getContext("2d"),
    oc = document.createElement('canvas'),
    octx = oc.getContext('2d');
    var tmp = document.createElement('canvas');
    var tmpCtx = tmp.getContext('2d');

    canvas.width = width;
    canvas.height = height === 0 ? canvas.width * image.height / image.width : height;
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL('image/' + type);
    if (image.width * step > width) { // For performance avoid unnecessary drawing
      var mul = 1 / step;
      var cur = {
          width: Math.floor(image.width * step),
          height: Math.floor(image.height * step)
      }

      oc.width = cur.width;
      oc.height = cur.height;
      tmp.width = cur.width;
      tmp.height = cur.height;
      octx.drawImage(image, 0, 0, cur.width, cur.height);

      while (cur.width * step > width) {
        cur = {
            width: Math.floor(cur.width * step),
            height: Math.floor(cur.height * step)
        };
        tmpCtx.clearRect(0, 0, cur.width * mul, cur.height * mul);
        tmpCtx.drawImage(oc, 0, 0, cur.width * mul, cur.height * mul, 0, 0, cur.width, cur.height);
        octx.clearRect(0, 0, cur.width * mul, cur.height * mul);
        octx.drawImage(tmp, 0, 0, cur.width, cur.height, 0, 0, cur.width, cur.height);
      }

      ctx.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, canvas.width, canvas.height);
    } else {
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    }
    return canvas.toDataURL('image/' + type);
    /*return new Promise((resolve) => {
        canvas.toBlob((file) => {
          resolve(URL.createObjectURL(file))
        }, 'image/jpeg')
      })*/
}