import React, { Component } from "react";
import licenseService from "../../services/license.service";
import { LineChart } from "../common/chart";
import LoadingComponent from "../common/loading";
export default class LicenseStats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            stats: [],
            cardStatsExpanded: []
        }
    }

    componentDidMount() {
        licenseService.getStats(this.props.organizationId, this.props.licenseId).then
        (
          response => {
            response.data.cardStats.sort(sortByProperty("name"))
            this.setState({
              loading: false,
              stats: response.data
            });
          },
          error => {
            this.setState({
              content:
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString()
            });
          }
        );
      }
    
    render() {

        if(this.state.loading === true) {
            return <LoadingComponent />
        }
        else {
            const linkTypeCounters = [];
            const cardCounters = [];
            const cardStats = [];
            let total = 0;
            let totalClicks = 0;
            let totalScans = 0;
            this.state.stats.linkTypeStats.forEach(function(item, index) {
                total += item.count;
                linkTypeCounters.push(
                <tr key={index}>
                    <td>{ item.type }</td><td className="num">{ item.count }</td>
                </tr>)
            });
            this.state.stats.cardStats.forEach(function(item, index) {
                totalScans += item.scans;
                totalClicks += item.clicks;

                cardCounters.push(
                    <tr key={index}>
                        <td>{ item.name }</td><td className="num">{ item.scans }</td><td className="num">{ item.clicks }</td>
                    </tr>
                )
                cardStats.push(<CardStatsComponent key={index} stats={item} />)
            });

        
            return (
                <div>
                    <table className="stats">
                        <tbody>
                            <tr>
                                <th>Link</th><th>Clicks</th>
                            </tr>
                            {linkTypeCounters}
                            <tr>
                                <td><b>Total</b></td><td className="num"><b>{ total }</b></td>
                            </tr>
                        </tbody>
                    </table>
                    <h2>Cards</h2>
                    <table className="stats">
                        <tbody>
                            <tr>
                                <th>Card</th><th>Scans</th><th>Clicks</th>
                            </tr>
                            {cardCounters}
                            <tr>
                                <td><b>Total</b></td><td className="num"><b>{ totalScans }</b></td><td className="num"><b>{ totalClicks }</b></td>
                            </tr>
                        </tbody>
                    </table>
                    {cardStats}
                    <LineChart
                        data={this.state.stats.clickStats}
                        title="Last 30 days"
                        color="#3E517A"
                    />
                </div>

            )
        }
    }
}
class CardStatsComponent extends Component {
    render() {
        const cardCounters = [];
        var total = 0;
        if(this.props.stats.linkTypeStats != null) {
            this.props.stats.linkTypeStats.forEach(function(item, index) {
                total += item.count;
                cardCounters.push(
                    <tr key={index}>
                        <td>{ item.name }</td><td className="num">{ item.count }</td>
                    </tr>
                )
            });
        }
      return (
        <table className="stats">
            <thead>
                <tr><th colSpan="2" className="card-stats-th"><h3>{this.props.stats.name}</h3></th></tr>
            </thead>
            <tbody>
                <tr>
                    <th>Link</th><th>Clicks</th>
                </tr>
                {cardCounters}
                <tr>
                    <td><b>Total</b></td><td className="num"><b>{total}</b></td>
                </tr>
            </tbody>
        </table>
      );
    }
}
function sortByProperty(property){  
    return function(a,b){  
       if(a[property] > b[property])  
          return 1;  
       else if(a[property] < b[property])  
          return -1;  
   
       return 0;  
    }  
 }