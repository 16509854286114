export const SERVER_BASE_URL = "https://app.digitalbooster.pro";
export const API_BASE_URL = SERVER_BASE_URL + "/";
//export const SERVER_BASE_URL = "http://192.168.0.110";
//export const API_BASE_URL = SERVER_BASE_URL + ":5000/";
export const DRINKS = "drinks"
export const ROLE_CONSTANTS = {
    Admin: 'ADMIN',
    Factory: 'FACTORY',
    Agent: 'AGENT',
    Client: 'CLIENT',
    Eployee: 'EMPLOYEE'
}

export const LINK_TYPES = {
    DigitalMenu: 'DIGITAL_MENU',
    Crew: 'CREW',
    Events: 'EVENTS',
    Equipment: 'EQUIPMENT',
    Facebook: 'FACEBOOK',
    Google: 'GOOGLE',
    Instagram: 'INSTAGRAM',
    Music: 'MUSIC',
    Question: 'QUESTION',
    Sailboat: 'SAILBOAT',
    Shop: 'SHOP',
    SpaAndFitness: 'SPA_AND_FITNESS',
    Speedboat: 'SPEEDBOAT',
    Tripadvisor: 'TRIPADVISOR',
    Website: 'WEBSITE',
    WelcomeBook: 'WELCOME_BOOK',
}