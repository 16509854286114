import axios from "axios";
import * as settings from '../constants'
import authHeader from './auth-header';
const API_URL = settings.API_BASE_URL + "api/";

class LinkTypeService {

  getLinkTypes() {
    return axios.get(API_URL + "link-types/", { headers: authHeader() });
  }
}

export default new LinkTypeService();