import React, { Component } from "react";
import Cropper from 'react-easy-crop'
import { getCroppedImg, steppedScale } from '../../utils/canvas'
import LicenseService from "../../services/license.service";
import { IconButton } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { PhotoCamera } from "@material-ui/icons";
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

export default class ChangeObjectLogo extends Component {

  constructor(props) {
    super(props);
    this.onFileChange = this.onFileChange.bind(this);

    this.onCropChange = this.onCropChange.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.onZoomChange = this.onZoomChange.bind(this);
    this.showCroppedImage = this.showCroppedImage.bind(this);

    this.state = {
      organizationId: this.props.organizationId,
      licenseId: this.props.licenseId,
      message: "",
      imageSrc: null,
      crop: { x: 0, y: 0 },
      croppedAreaPixels: null,
      zoom: 1,
      aspect: 508 / 312,
      croppedImage: null,
      rotation: 0,
      editing: false
    };
  }

  onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file)
      this.setState({imageSrc: imageDataUrl, editing: true})
    }
  }

  onCropChange = (crop) => {
    this.setState({ crop: crop })
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({croppedAreaPixels: croppedAreaPixels});
  }

  onZoomChange = (zoom) => {
    this.setState({ zoom })
  }

  cancelEditing = () => {
    this.setState({ editing: false, croppedImage: null, imageSrc: null })
    document.getElementById("icon-button-logo").value = "";
  }

  showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        this.state.imageSrc,
        this.state.croppedAreaPixels,
        this.state.rotation,
        'png'
      )
      const scaledImage = await steppedScale(croppedImage, 508, 312, 0.75, 'png')
      await LicenseService.uploadObjectLogo(this.state.organizationId, this.state.licenseId, scaledImage);
      this.cancelEditing();
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    return (
      <div className="flex-center">
        <input type="file" className="hidden" id="icon-button-logo" onChange={this.onFileChange} accept="image/*" />
        <label htmlFor="icon-button-logo" className={this.state.editing ? "hidden" : ""}>
        <IconButton color="primary" aria-label="upload picture" component="span">
        <Button variant="contained" color="primary" component="span">
        <PhotoCamera /> &nbsp;&nbsp;Upload logo
        </Button>
        </IconButton>
        </label>
        <div className={this.state.editing ? "padding-bottom-5" : " hidden"}>
          <Button variant="contained" color="secondary" component="span" onClick={this.cancelEditing}><CancelIcon />&nbsp;&nbsp; Cancel</Button>
          &nbsp;&nbsp;
          <Button variant="contained" color="primary" component="span" onClick={this.showCroppedImage}><SaveIcon />&nbsp;&nbsp; Save</Button>
        </div>
        <div className="break"></div>
        {this.state.imageSrc && !this.state.croppedImage ? (
        <div className="crop-container">
            <Cropper
              image={this.state.imageSrc}
              crop={this.state.crop}
              rotation={this.state.rotation}
              zoom={this.state.zoom}
              aspect={this.state.aspect}
              onCropChange={this.onCropChange}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.onZoomChange}
              restrictPosition={false}
              minZoom="0.5"
            />
        </div>
        ) : <img src={this.props.license.image + "-logo?" + Date.now()} alt="Logo" className="object-image image-upload" />}
      </div>
    );
  }
}

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}