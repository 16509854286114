import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import LicenseService from "../../services/license.service"

import { Redirect } from "react-router";
import LoadingComponent from "../common/loading"

const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

export default class CreateLicense extends Component {
  constructor(props) {
    super(props);

    this.handleCreate = this.handleCreate.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeObjectType = this.onChangeObjectType.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.cancel_onClick = this.cancel_onClick.bind(this);

    this.state = {
      id: "",
      organizationId: this.props.match.params.organizationId,
      name: "",
      objectType: "",
      type: "7",
      objectTypes: [],
      successful: false,
      message: "",
      loading: true
    };
  }

  onChangeName(e) {
    this.setState({
      name: e.target.value
    });
  }

  onChangeObjectType(e) {
    this.setState({
      objectType: e.target.value
    });
  }

  onChangeType(e) {
    this.setState({
      type: e.target.value
    });
  }
  
  cancel_onClick(e) {
    e.preventDefault();
    e.stopPropagation();
    window.history.back();
  }

  handleCreate(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
      id: ""
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      LicenseService.create(
        this.state.organizationId,
        this.state.name,
        this.state.objectType,
        this.state.type
      ).then(
        response => {
          this.setState({
            id: response.data.id,
            successful: true
          });
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }
  }

  componentDidMount() {
    LicenseService.getObjectTypes().then(
      response => {
        this.setState({
          loading: false,
          objectTypes: response.data,
          objectType: response.data[0].id
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }
  render() {

    if(this.state.loading === true) {
      return <LoadingComponent />;
    }
    else
    {
      const objectTypes = [];
      this.state.objectTypes.forEach(function(item) {
        objectTypes.push(<option key={item.id} value={item.id}>{item.name}</option>)
      });
      return (
        <div className="col-md-12">
            {this.state.id !== "" && (
                <Redirect to={"/organizations/" + this.state.organizationId + "/licenses/" + this.state.id}></Redirect>
            )}
          <div className="card card-container">
            <img
              src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
              alt="profile-img"
              className="profile-img-card"
            />

            <Form
              onSubmit={this.handleCreate}
              ref={c => {
                this.form = c;
              }}
            >
              {!this.state.successful && (
                <div>
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="name"
                      value={this.state.name}
                      onChange={this.onChangeName}
                      validations={[required]}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="objectType">Object type</label>
                    <Select
                      className="form-control"
                      name="objectType"
                      value={this.state.objectType}
                      onChange={this.onChangeObjectType}
                      validations={[required]}
                    >
                      {objectTypes}
                  </Select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="type">Type</label>
                    <Select
                      className="form-control"
                      name="type"
                      value={this.state.type}
                      onChange={this.onChangeType}
                      validations={[required]}
                    >
                      <option value="7">Trial 7 days</option>
                      <option value="15">Trial 15 days</option>
                      <option value="M">Trial 1 month</option>
                      <option value="F">Subscription</option>
                  </Select>
                  </div>
                  <div className="form-group">
                    <button className="btn btn-block">Create</button>
                    <button className="btn btn-block" onClick={this.cancel_onClick}>Cancel</button>
                  </div>
                </div>
              )}

              {this.state.message && (
                <div className="form-group">
                  <div
                    className={
                      this.state.successful
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {this.state.message}
                  </div>
                </div>
              )}
              <CheckButton
                style={{ display: "none" }}
                ref={c => {
                  this.checkBtn = c;
                }}
              />
            </Form>
          </div>
        </div>
      );
    }
  }
}