import React, { Component } from "react";
import LoadingComponent from "../common/loading";
import licenseService from "../../services/license.service";
import { Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import linkService from "../../services/link.service";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { ROLE_CONSTANTS, SERVER_BASE_URL } from "../../constants";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import BreadcrumbsComponent from "../common/breadcrumbs";
import authService from "../../services/auth.service";
import { lang } from "moment";

export default class LicenseLinkEdit extends Component {
  constructor(props) {
    super(props);
    this.onLinkSelect = this.onLinkSelect.bind(this)
    this.handleTypeChange = this.handleTypeChange.bind(this)
    this.onLinkChange = this.onLinkChange.bind(this)
    this.onLinkFocusOut = this.onLinkFocusOut.bind(this)
    this.onLanguageLinksChange = this.onLanguageLinksChange.bind(this)
    this.onLanguageLinksFocusOut = this.onLanguageLinksFocusOut.bind(this)
    this.uploadLanguageChange = this.uploadLanguageChange.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.state = {
      link: {},
      linkValue: "",
      language: {name:"English", isoCode: "en"},
      uploading: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.getLink(this.props.openedLinkId)
  }
  getLink(linkId) {
    licenseService.getLink(this.props.organizationId, this.props.license.id, linkId).then
    (
      response => {
        var link = response.data;
        this.prepareData(link);
        this.setState({
          link: link,
          linkValue: link.value,
          loading: false
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

  }
  onLinkSelect(parentId, linkId, name) {
    if(this.props.openedLinkId !== null) {
      this.getLink(linkId)
    }
    this.props.handleLinkSelect(parentId, linkId, name);
  }
  handleTypeChange(e, type) {
    linkService.updateLinkType(this.props.organizationId, this.props.license.id, this.props.openedLinkId, type)
    var link = this.state.link
    link.type = type
    link = this.prepareData(link)
    this.setState({
      link: link
    })
  }
  onLinkChange(event) {
    let link = this.state.link
    link.value = event.target.value
    this.setState({link: link})
  }
  onLinkFocusOut(event) { 
    let linkValue = this.state.linkValue
    let link = this.state.link
    if(linkValue === event.target.value) {
      return;
    }
    link.value = event.target.value
    this.setState({
      link: link,
      linkValue: link.value
    });
    linkService.update(this.props.organizationId, this.props.licenseId, link.id, event.target.value);
  }

  onLanguageLinksChange(event, language) {
    var link = this.state.link
    link.data[link.type][language] = event.target.value
    this.setState({link: link})
  }

  onLanguageLinksFocusOut(event, language, linkId) {
    linkService.update(this.props.organizationId, this.props.license.id, linkId, event.target.value, null, language);
  }

  uploadLanguageChange(language) {
    this.setState({language: language})
  }

  onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let fileDataUrl = await readFile(file)
      this.setState({ uploading: true});
      licenseService.uploadFile(this.props.organizationId, this.props.licenseId, this.state.link.id, this.state.language.isoCode, file.name, fileDataUrl)
        .then
        (
          res => {
            const link = this.state.link
            link.data["upload"][this.state.language.isoCode] = res.data.value
            this.setState({uploading: false})
          }
        );
    }
  }

  onDelete(e, linkId, languageCode) {
    e.preventDefault()
    e.stopPropagation()
    linkService.deleteUpload(this.props.organizationId, this.props.license.id, linkId, languageCode)
    var link = this.state.link
    link.data["upload"][this.state.language.isoCode] = ""
    this.setState({link: link})
  }

  prepareData(link) {
    if(typeof(link.data) === "string") {
      link.data = JSON.parse(link.data)
    }
    if(!("data" in link)) {
      link["data"] = {}
    }
    if(link.data === null) {
      link.data = {}
    }
    if(!(link.type in link.data)) {
      link.data[link.type] = {};
    }
    if(link.type === "language-links" || link.type === "upload") {
      this.props.license.languages.forEach((language) => {
        if(!(language.isoCode in link.data[link.type]))
          link.data[link.type][language.isoCode] = "";
      });
    }
    return link
  }

  render() {
    if(this.state.loading === true) {
      return <LoadingComponent />;
    }
    else {
      const rThis = this
      var link = this.state.link
      const user = authService.getCurrentUser()
      const admin = user.role.includes(ROLE_CONSTANTS.Agent) || user.role.includes(ROLE_CONSTANTS.Admin)
      return (
            <div><BreadcrumbsComponent handleLinkSelect={this.onLinkSelect} handleLinkMenuChange={this.props.handleLinkMenuChange} breadcrumbs={this.props.breadcrumbs}/><hr />
              <FormControl component="fieldset">
                <RadioGroup row aria-label="type" value={link.type} name="type" onChange={this.handleTypeChange}>
                  <FormControlLabel value="link" control={<Radio />} label="Link" />
                  <FormControlLabel value="language-links" control={<Radio />} label="Language links" />
                  <FormControlLabel value="upload" control={<Radio />} label="Upload"  />
                  {(link.children > 0 || admin) && (<FormControlLabel value="menu" control={<Radio />} label="Menu"  />)}
                </RadioGroup>
              </FormControl>
              <hr />
              { link.type === "link" && (<div> 
                <TextField
                onBlur={this.onLinkFocusOut}
                onChange={this.onLinkChange}
                className="text-input"
                margin="dense"
                id={"link-" + this.props.number}
                label={this.props.name}
                type="text"
                value={link.value}
                fullWidth
              /></div>)}
              { link.type === "language-links" && (<div>
                {
                  this.props.license.languages.map((language, index) => {
                  if(language.id === rThis.props.license.languageId)
                  return (
                  <div key={language.id}>
                    <TextField
                    onBlur={(e) => {rThis.onLanguageLinksFocusOut(e, language.isoCode, link.id)}}
                    onChange={(e) => {rThis.onLanguageLinksChange(e, language.isoCode)}}
                    className="text-input"
                    margin="dense"
                    id={"link-" + index}
                    label={language.name}
                    type="text"
                    value={rThis.state.link.data["language-links"][language.isoCode]}
                    fullWidth
                    />
                  </div>)
                } )}
              </div>)
              }
              { link.type === "language-links" && (<div>
                {
                  this.props.license.languages.map((language, index) => {
                  if(language.id !== rThis.props.license.languageId) {
                  return (
                  <div key={language.id}>
                    <TextField
                    onBlur={(e) => {rThis.onLanguageLinksFocusOut(e, language.isoCode, link.id)}}
                    onChange={(e) => {rThis.onLanguageLinksChange(e, language.isoCode)}}
                    className="text-input"
                    margin="dense"
                    id={"link-" + index}
                    label={language.name}
                    type="text"
                    value={rThis.state.link.data["language-links"][language.isoCode]}
                    fullWidth
                    />
                  </div>)
                  }
                } )}
              </div>)
              }
              { link.type === "upload" && (
                <div>
                  { this.props.license.languages.map((language) => {
                    if(language.id == this.props.license.languageId) {
                    return <img onClick={(e) => {rThis.uploadLanguageChange(language)}} key={language.id} width="75" className={this.state.language.isoCode === language.isoCode ? "cursor-pointer" : "transparent cursor-pointer" + (this.state.link.data["upload"][language.isoCode] !== "" ? "" : " disabled")} src={SERVER_BASE_URL + "/static/themes/default/flags/" + language.isoCode + ".png"} />
                    }
                  })}
                  { this.props.license.languages.map((language) => {
                    if(language.id != this.props.license.languageId) {
                    return <img onClick={(e) => {rThis.uploadLanguageChange(language)}} key={language.id} width="75" className={this.state.language.isoCode === language.isoCode ? "cursor-pointer" : "transparent cursor-pointer" + (this.state.link.data["upload"][language.isoCode] !== "" ? "" : " disabled")} src={SERVER_BASE_URL + "/static/themes/default/flags/" + language.isoCode + ".png"} />
                    }
                  })}
                  <hr />
                  <h3>{this.state.language.name}</h3>
                  <br />
                  <input type="file" className="hidden" id="file" onChange={this.onFileChange} accept="application/pdf,image/jpeg,image/gif,image/png" />
                  <label htmlFor="file">
                    <Button variant="contained" color="primary" component="span">
                      <CloudUploadIcon /> &nbsp;&nbsp;Upload image / PDF
                    </Button>
                  </label>
                  { this.state.link.data["upload"][this.state.language.isoCode] !== "" && (
                    <a href={SERVER_BASE_URL + "/static/images/" + this.state.link.data["upload"][this.state.language.isoCode] + "?" + Date.now()} className="btn btn-primary" target="_blank" rel="noreferrer">
                      <PictureAsPdfIcon /> Preview
                    </a>
                  )}
                  <br />
                  { this.state.link.data["upload"][this.state.language.isoCode] !== "" && (
                      <a href="/" onClick={(e) => this.onDelete(e, link.id, this.state.language.isoCode)} className="btn btn-danger">
                        <HighlightOffIcon /> Delete
                      </a>
                  )}
                </div>)
              }
              { link.type === "menu" && (<div><Button onClick={((e) => { this.props.handleLinkMenuChange(this.state.link.id)})} variant="contained" color="primary" component="span">Edit menu</Button></div>)}
            </div>
      );
    }
  }
}
function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}