import axios from "axios";
import * as settings from '../constants'
import authHeader from './auth-header';
const API_URL = settings.API_BASE_URL + "api/organizations/";

class OrganizationService {
  readLicenses(organizationId) {
    return axios.get(API_URL + organizationId + "/licenses", { headers: authHeader() });
  }

  readLicense(organizationId, licenseId) {
    return axios.get(API_URL + organizationId + "/licenses/" + licenseId, { headers: authHeader() });
  }

  readLicenseCards(organizationId, licenseId) {
    return axios.get(API_URL + organizationId + "/licenses/" + licenseId + "/cards", { headers: authHeader() });
  }
}

export default new OrganizationService();