import React, { Component } from "react";
import { Redirect } from "react-router";
import ClientService from "../services/client.service";

import LoadingComponent from "./common/loading";
import Moment from 'moment';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { Link } from "react-router-dom";


export default class PanelClient extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      organization: [],
      error: false
    };
  }

  componentDidMount() {
    ClientService.info().then(
      response => {
        this.setState({
          loading: false,
          organization: response.data
        });
      },
      error => {
        this.setState({
          error: true
        });
      }
    );
  }

  render() {
    const organization = this.state.organization;
    if(this.state.loading === true) {
      return <LoadingComponent />;
    }
    else {
      const classes = useStyles;
      if(organization.licenses.length === 1) {
        return <Redirect to={"/organizations/" + organization.id + "/licenses/" + organization.licenses[0].id} />
      }
      return (
        <div className="container text-center">
          <h1>Select license</h1>
          <br /><br />
          <div className={classes.root + " licenses-wrapper"}>
            <GridList cellHeight={180} className={classes.gridList + " flex-center"}>
              {organization.licenses.map((license) => (
                  <GridListTile className="license-image-background" key={license.id}>
                    <Link key={license.id} to={"/organizations/" + organization.id + "/licenses/" + license.id}>
                    <img className="license-image" src={license.image + "-logo"} alt={license.name}/>
                    <GridListTileBar className="license-title"
                      title={license.name}
                      subtitle={<span>{Moment(license.expirationDate).format('L')}</span>}
                    />
                    </Link>
                  </GridListTile>
              ))}
            </GridList>
          </div>
        </div>
      );
    }
  }
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));