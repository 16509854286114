import React, { Component } from "react";
import ClientService from "../../services/client.service";
import LoadingComponent from "../common/loading";
import Licenses from "../licenses/licenses";

export default class ReadClient extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      loading: true,
      id: this.props.match.params.id,
      userName: "",
      email: "",
      firstName: "",
      lastName: "",
      organizationName: "",
      message: "",
    };

    this.handleFocusOut = this.handleFocusOut.bind(this);
  }

  componentDidMount() {
    ClientService.read(this.state.id).then(
      response => {
        this.setState({
          id: response.data.organization.id,
          loading: false,
          organizationName: response.data.organization.name,
          email: response.data.email,
          phoneNumber: response.data.phoneNumber
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  handleFocusOut(text, props) {
    if(this.state[props.name] === text) {
      return;
    }
    var getNewState = function (name) {
      switch (name) {
        case "email":
          return { email: "" };
        case "phoneNumber":
          return { phoneNumber: "" };
        case "lastName":
          return { lastName: "" };
        case "organizationName":
          return { organizationName: ""};
        default:
      }
    }
 
    var newState = getNewState(props.name);
    newState[props.name] = text;
    this.setState(newState);
  }

  render() {
    if(this.state.loading === true) {
      return <LoadingComponent />;
    }
    else return (
      <div className="col-md-12">
        <Licenses organizationId={this.state.id} />
      </div>
    );
  }
}