import React, { Component } from "react";
import OrganizationService from "../../services/organization.service"
import Moment from 'moment';
import { Link } from "react-router-dom";
import LicenseLink from "../links/license-link";
import Cards from "../cards/cards";

export default class LicenseEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      organizationId: this.props.match.params.organizationId,
      licenseId: this.props.match.params.licenseId,
      license: [],
      message: "",
    };
  }

  componentDidMount() {
    OrganizationService.readLicense(this.state.organizationId, this.state.licenseId).then
    (
      response => {
        response.data.links.sort((a, b) => (a.order > b.order) ? 1 : -1)
        this.setState({
          loading: false,
          license: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }
  render() {
    Moment.locale('en');
    const licenseLinks = [];
    const links = this.state.license.links;
    const organizationId = this.state.organizationId;
    const licenseId = this.state.licesneId;
    if(typeof(links) !== 'undefined') {
      links.forEach(function(item) {
        licenseLinks.push(<LicenseLink organizationId={organizationId} licenseId={licenseId} key={item.id} {...item} />)
      });
    }

    return (
      <div>
        <div>
          <h2>{ this.state.license.name }</h2>
          { Moment(this.state.license.expirationDate).format('L') } <br /><br />
        </div>
        <div><Link to={"/organizations/" + this.state.organizationId + "/licenses/" + this.state.licenseId + "/upload-object-image" } className="btn btn-primary button-width">Upload background</Link>
        </div>
        <div><Link to={"/organizations/" + this.state.organizationId + "/licenses/" + this.state.licenseId + "/upload-object-logo" } className="btn btn-primary button-width">Upload logo</Link>
        </div>
        <br /><br /><br />
        <h3>Links</h3>
        {licenseLinks}
        <br />
        <Cards organizationId={this.state.organizationId} licenseId={this.state.licenseId}/>
      </div>
    );
  }
}