import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import PanelAgent from "./components/panel-agent.component";
import PanelUser from "./components/board-user.component";

import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import { ROLE_CONSTANTS } from "./constants";
import CreateClient from "./components/clients/create";
import ReadClient from "./components/clients/read";
import CreateLicense from "./components/licenses/create";
import LicenseEdit from "./components/licenses/license-edit"
import ChangeObjectImage from "./components/licenses/change-object-image";
import ChangeObjectLogo from "./components/licenses/change-object-logo";
import AddCard from "./components/cards/add-card";
import PanelClient from "./components/panel-client.component";
import LicenseDetails from "./components/licenses/license-details";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PersonIcon from '@material-ui/icons/Person';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LoadingComponent from "./components/common/loading";
import userService from "./services/user.service";
class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDialogOpen = this.handleDialogOpen.bind(this);
    this.handlePresentationCardDialogOpen = this.handlePresentationCardDialogOpen.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handlePresentationCardDialogClose = this.handlePresentationCardDialogClose.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangePresentationCard = this.handleChangePresentationCard.bind(this);
    this.handleCurrentPassword = this.handleCurrentPassword.bind(this);
    this.handlePresentationCard = this.handlePresentationCard.bind(this);
    this.handleNewPassword = this.handleNewPassword.bind(this);
    this.handleNewPasswordAgain = this.handleNewPasswordAgain.bind(this);
    this.state = {
      showAdminPanel: false,
      showFactoryPanel: false,
      showAgentPanel: false,
      showClientPanel: false,
      showEmployeePanel: false,
      currentUser: undefined,
      anchorEl: null,
      login: false,
      open: false,
      currentPassword: "",
      newPassword: "",
      newPasswordAgain: "",
      loader: false,
      changeSuccess: false,
      presentationCard: "",
      presentationCardDb: "",
      presentationCardOpen: false
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showAdminPanel: user.role.includes(ROLE_CONSTANTS.Admin),
        showFactoryPanel: user.role.includes(ROLE_CONSTANTS.Factory),
        showAgentPanel: user.role.includes(ROLE_CONSTANTS.Agent),
        showClientPanel: user.role.includes(ROLE_CONSTANTS.Client),
        showEmployeePanel: user.role.includes(ROLE_CONSTANTS.Eployee),
        presentationCardOpen: false,
        open: false
      });
    }
    userService.getUserInfo().then(
      response => {
        this.setState({
          presentationCardDb: response.data.presentationCard,
          presentationCard: response.data.presentationCard
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  logOut() {
    AuthService.logout();
    document.location.href = "/login";
  }
  handleClick = (event) => {
    this.setState({anchorEl: event.currentTarget});
  };
  handleClose = () => {
    this.setState({anchorEl: null});
  };
  handleDialogOpen = () => {
    this.setState({open: true});
  };
  handlePresentationCardDialogOpen = () => {
    this.setState({presentationCardOpen: true});
  };

  handleChangePassword = () => {
    const curr = this.state.currentPassword
    if(curr.length === 0) {
      this.setState({error: "Enter current password"})
      return
    }
    const newPass = this.state.newPassword
    const newPassAgain = this.state.newPasswordAgain
    if(newPass.length < 8) {
      this.setState({error: "Password must be at least 8 characters long!"})
      return
    }
    if(newPass !== newPassAgain) {
      this.setState({error: "Passwords do not match"})
      return
    }
    this.setState({loading: true})
    AuthService.changePassword(curr, newPass).then
    (
      response => {
        this.setState({
          loading: false,
          changeSuccess: true
        });
        //this.handleDialogClose();
      },
      error => {
        this.setState({
          error: error.response.data.error,
          loading: false
        });
      }
    );
  }
  handleChangePresentationCard = () => {
    const presentationCard = this.state.presentationCard
    userService.setPresentationCard(presentationCard).then
    (
      response => {
        this.setState({
          loading: false,
          changeSuccess: true,
          presentationCardDb: presentationCard
        });
      },
      error => {
        var presentationCardDb = this.state.presentationCardDb;
        this.setState({
          error: error.response.data.error,
          loading: false,
          presentationCard: presentationCardDb
        });
      }
    );
  }
  handleDialogClose = () => {
    this.setState({open: false, error: "", currentPassword: "", newPassword: "", newPasswordAgain: "", changeSuccess: false});
  };
  handlePresentationCardDialogClose = () => {
    this.setState({presentationCardOpen: false, changeSuccess: false, error: null, presentationCard: this.state.presentationCardDb});
  };
  
  handleCurrentPassword = (event) => {
    this.setState({currentPassword: event.target.value});
  };
  handlePresentationCard = (event) => {
    this.setState({presentationCard: event.target.value});
  };
  handleNewPassword = (event) => {
    this.setState({newPassword: event.target.value});
  };
  handleNewPasswordAgain = (event) => {
    this.setState({newPasswordAgain: event.target.value});
  };

  render() {
    const { currentUser, showAdminPanel, showFactoryPanel, showAgentPanel, showClientPanel, showEmployeePanel } = this.state;
    return (
      <div>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link to={"/"} className="navbar-brand">
            DB Panel
          </Link>
          <div className="navbar-nav mr-auto">
            {showAdminPanel && (
              <li className="nav-item">
                <Link to={"/admin"} className="nav-link">
                  Home
                </Link>
              </li>
            )}

            {showFactoryPanel && (
              <li className="nav-item">
                <Link to={"/factory"} className="nav-link">
                  Home
                </Link>
              </li>
            )}

            {showAgentPanel && (
              <li className="nav-item">
                <Link to={"/agent"} className="nav-link">
                  Home
                </Link>
              </li>
            )}

            {showClientPanel && (
              <li className="nav-item">
                <Link to={"/client"} className="nav-link">
                  Home
                </Link>
              </li>
            )}

            {showEmployeePanel && (
              <li className="nav-item">
                <Link to={"/employee"} className="nav-link">
                  Home
                </Link>
              </li>
            )}

            {/* {currentUser && (
              <li className="nav-item">
                <Link to={"/user"} className="nav-link">
                  User
                </Link>
              </li>
            )} */}
          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
              <div>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
                  <PersonIcon className="background-white" />
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleClose}><PersonIcon />&nbsp;&nbsp;Profile</MenuItem>
                  <MenuItem onClick={this.handleDialogOpen}><LockIcon />&nbsp;&nbsp;Password</MenuItem>
                  {showAgentPanel && <MenuItem onClick={this.handlePresentationCardDialogOpen}><CreditCardIcon />&nbsp;&nbsp;Presentation card</MenuItem>}
                  <MenuItem onClick={this.logOut}><ExitToAppIcon />&nbsp;&nbsp;Logout</MenuItem>
                </Menu>
              </div>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </li>

              <li className="nav-item">
                <Link to={"/register"} className="nav-link">
                  Sign Up
                </Link>
              </li>
            </div>
          )}
        </nav>
        <Dialog open={this.state.presentationCardOpen} onClose={this.handlePresentationCardDialogClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            <span className={this.state.changeSuccess ? "hidden": ""}>Presentation card</span>
            <span className={this.state.changeSuccess ? "": "hidden"}>Presentation card saved successfully!</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
            <span className={this.state.changeSuccess ? "hidden": ""}>
              Enter Serial number of presentation card.
            </span>
            <span className={(this.state.changeSuccess ? "" : "hidden") + " align-center"} >
              <CheckCircleOutlineIcon fontSize="large" />
            </span>
            </DialogContentText>
            <span className={this.state.changeSuccess ? "hidden": ""}>
              <TextField
                onChange={this.handlePresentationCard}
                className="text-input"
                autoFocus
                margin="dense"
                id="presentationCard"
                label="Card serial number"
                type="text"
                value={this.state.presentationCard}
                disabled={this.state.loading}
                fullWidth
              />
              <br /><br />
              {this.state.error ? <div className="error">{this.state.error}</div> : null}
            </span>
          </DialogContent>
          <DialogActions>
            <span className={this.state.changeSuccess || this.state.loading ? "hidden": ""}>
              <Button onClick={this.handlePresentationCardDialogClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleChangePresentationCard} color="primary">
                Save
              </Button>
            </span>
            <span className={this.state.loading ? "": "hidden"}>
              <LoadingComponent />
            </span>
            <span className={this.state.changeSuccess ? "": "hidden"}>
              <Button onClick={this.handlePresentationCardDialogClose} color="primary">
                  Close
              </Button>
            </span>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.open} onClose={this.handleDialogClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            <span className={this.state.changeSuccess ? "hidden": ""}>Password change</span>
            <span className={this.state.changeSuccess ? "": "hidden"}>Password changed successfully!</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
            <span className={this.state.changeSuccess ? "hidden": ""}>
              To change your password, please enter your current password and your new password.
            </span>
            <span className={(this.state.changeSuccess ? "" : "hidden") + " align-center"} >
              <CheckCircleOutlineIcon fontSize="large" />
            </span>
            </DialogContentText>
            <span className={this.state.changeSuccess ? "hidden": ""}>
              <TextField
                onChange={this.handleCurrentPassword}
                className="text-input"
                autoFocus
                margin="dense"
                id="password"
                label="Current password"
                type="password"
                disabled={this.state.loading}
                fullWidth
              />
              <TextField
                onChange={this.handleNewPassword}
                className="text-input"
                margin="dense"
                id="new_pasword"
                label="New password"
                type="password"
                disabled={this.state.loading}
                fullWidth
              />
              <TextField
                onChange={this.handleNewPasswordAgain}
                className="text-input"
                margin="dense"
                id="new_password_again"
                label="New password again"
                type="password"
                disabled={this.state.loading}
                fullWidth
              />
              <br /><br />
              {this.state.error ? <div className="error">{this.state.error}</div> : null}
            </span>
          </DialogContent>
          <DialogActions>
            <span className={this.state.changeSuccess || this.state.loading ? "hidden": ""}>
              <Button onClick={this.handleDialogClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleChangePassword} color="primary">
                Change
              </Button>
            </span>
            <span className={this.state.loading ? "": "hidden"}>
              <LoadingComponent />
            </span>
            <span className={this.state.changeSuccess ? "": "hidden"}>
              <Button onClick={this.handleDialogClose} color="primary">
                  Close
              </Button>
            </span>
          </DialogActions>
        </Dialog>
        <div className="container mt-3">
          <Switch>
            <Route exact path={["/", "/home"]} component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/profile" component={Profile} />
            <Route path="/agent" component={PanelAgent} />
            <Route path="/user" component={PanelUser} />
            <Route path="/client" component={PanelClient} />
            <Route path="/clients/create" component={CreateClient} />
            <Route exact path="/clients/:id" render={(props) => <ReadClient {...props} /> } />
            <Route exact path="/organizations/:organizationId/licenses/create" component={CreateLicense} />
            <Route exact path="/organizations/:organizationId/licenses/:licenseId" component={LicenseDetails  } />
            <Route exact path="/organizations/:organizationId/licenses/:licenseId/edit" component={LicenseEdit} />
            <Route exact path="/organizations/:organizationId/licenses/:licenseId/upload-object-image" component={ChangeObjectImage} />
            <Route exact path="/organizations/:organizationId/licenses/:licenseId/upload-object-logo" component={ChangeObjectLogo} />
            <Route exact path="/organizations/:organizationId/licenses/:licenseId/cards/create" component={AddCard} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
