import axios from 'axios';
import { API_BASE_URL } from '../constants';
import authHeader from './auth-header';

const API_URL = API_BASE_URL + 'api/dashboard/';

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getAdminPanel() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }

  getFactoryPanel() {
    return axios.get(API_URL + 'factory', { headers: authHeader() });
  }

  getAgentPanel() {
    return axios.get(API_URL + 'agent', { headers: authHeader() });
  }

  getClientPanel() {
    return axios.get(API_URL + 'client', { headers: authHeader() });
  }

  getEmployeePanel() {
    return axios.get(API_URL + 'employee', { headers: authHeader() });
  }

  getUserPanel() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getUserInfo() {
    return axios.get(API_BASE_URL + 'api/users/info', { headers: authHeader() })
  }
  setPresentationCard(serialNo) {
    return axios.post(API_BASE_URL + 'api/users/set-presentation-card/' + serialNo, {data: {}}, { headers: authHeader()})
  }
}

export default new UserService();