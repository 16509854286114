import axios from "axios";
import { Redirect } from "react-router";
import * as settings from '../constants'
import authHeader from './auth-header';
const API_URL = settings.API_BASE_URL + "api/auth/";

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "login", {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }

  changePassword(currentPassword, newPassword) {
    return axios.post(API_URL + "change-password", {
      currentPassword,
      newPassword
    }, { headers: authHeader() });
  }

  Login() {
    return <Redirect to="/login" />;
  }
}

export default new AuthService();