import axios from "axios";
import * as settings from '../constants'
import authHeader from './auth-header';
const API_URL = settings.API_BASE_URL + "api/";

class LicenseService {
  create(organizationId, name, objectTypeId, type) {
    return axios.post(API_URL + "organizations/" + organizationId + "/licenses", {
      name,
      objectTypeId,
      type
    }, { headers: authHeader() });
  }
  getObjectTypes() {
    return axios.get(API_URL + "object-types", { headers: authHeader() });
  }
  read(organizationId, id) {
    return axios.get(API_URL + "organizations/" + organizationId + "/licenses/" + id, { headers: authHeader() });
  }
  uploadObjectImage(organizationId, id, file) {
    return axios.post(API_URL + "organizations/" + organizationId + "/licenses/" + id + "/upload-object-image", {data: file.split(',')[1]}, { headers: authHeader() });
  }
  uploadObjectLogo(organizationId, id, file) {
    return axios.post(API_URL + "organizations/" + organizationId + "/licenses/" + id + "/upload-object-logo", {data: file.split(',')[1]}, { headers: authHeader() });
  }
  uploadDigitalMenu(organizationId, id, language, file) {
    return axios.post(API_URL + "organizations/" + organizationId + "/licenses/" + id + "/digital-menu/" + language, {data: file.split(',')[1]}, { headers: authHeader() });
  }
  deleteDigitalMenu(organizationId, id, language) {
    return axios.delete(API_URL + "organizations/" + organizationId + "/licenses/" + id + "/digital-menu/" + language, { headers: authHeader(), data: {} });
  }
  uploadWelcomeBook(organizationId, id, file) {
    return axios.post(API_URL + "organizations/" + organizationId + "/licenses/" + id + "/welcome-book", {data: file.split(',')[1]}, { headers: authHeader() });
  }
  updateTheme(organizationId, id, themeId) {
    return axios.post(API_URL + "organizations/" + organizationId + "/licenses/" + id + "/theme/" + themeId, {data: {}}, { headers: authHeader() });
  }
  updateLanguages(organizationId, licenseId, languages) {
    return axios.post(API_URL + "organizations/" + organizationId + "/licenses/" + licenseId + "/languages", {data: {languages: languages}}, { jheaders: authHeader()});
  }
  updateLongReviewButtons(organizationId, id, longReviewButtons) {
    return axios.post(API_URL + "organizations/" + organizationId + "/licenses/" + id + "/long-review-buttons/" + longReviewButtons, {data: {}}, { headers: authHeader() });
  }
  getStats(organizationId, id) {
    return axios.get(API_URL + "organizations/" + organizationId + "/licenses/" + id + "/stats", { headers: authHeader() });
  }
  addLanguage(organizationId, licenseId, languageId) {
    return axios.post(API_URL + "organizations/" + organizationId + "/licenses/" + licenseId + "/languages/" + languageId, {data: {}}, { headers: authHeader() });
  }
  removeLanguage(organizationId, licenseId, languageId) {
    return axios.delete(API_URL + "organizations/" + organizationId + "/licenses/" + licenseId + "/languages/" + languageId, { headers: authHeader(), data: {} });
  }
  setDefaultLanguage(organizationId, licenseId, languageId) {
    return axios.post(API_URL + "organizations/" + organizationId + "/licenses/" + licenseId + "/languages/default/" + languageId, {data: {}}, { headers: authHeader() });
  }
  getLinks(organizationId, licenseId, parentId) {
    return axios.get(API_URL + "organizations/" + organizationId + "/licenses/" + licenseId + "/links/" + (parentId ?? ""), { headers: authHeader() });
  }
  getLink(organizationId, licenseId, linkId) {
    return axios.get(API_URL + "organizations/" + organizationId + "/licenses/" + licenseId + "/link/" + linkId, { headers: authHeader() });
  }
  uploadFile(organizationId, licenseId, linkId, language, fileName, file) {
    return axios.post(API_URL + "organizations/" + organizationId + "/licenses/" + licenseId + "/link/" + linkId + "/upload/" + language, {data: file.split(',')[1], fileName: fileName}, { headers: authHeader() });
  }
  updateLicense(organizationId, licenseId, name) {
    return axios.patch(API_URL + "organizations/" + organizationId + "/licenses/" + licenseId, {name: name}, { headers: authHeader() });
  }
  

}

export default new LicenseService();